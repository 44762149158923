'use client';
import { useSession, signOut } from 'next-auth/react';
import { useMyInfo } from './use-myinfo';
import { useEffect, useState } from 'react';
import { plugin_dayjs } from '../../utils/plugin/dayjs';
import { plugin_zod } from '../../utils/plugin/zod_meta';
import { AuthTokenClient } from './auth_client';
import dayjs from 'dayjs';

const isDebugMode = process.env.NEXT_PUBLIC_DEV_MODE == 'true';

export function AuthLoadContainer({ children }: { children: React.ReactNode }) {
  const session = useSession();
  const myInfo = useMyInfo();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    plugin_dayjs();
    plugin_zod();

    if(isDebugMode){
      //@ts-ignore
      window._dayjs = dayjs;
    }

    AuthTokenClient.resetComponent(session.update, signOut);
    AuthTokenClient.setLoginData(session.data);

    if(session.data?.user){
      myInfo.refreshMyinfo().then(()=>{
        setIsReady(true);
      });
    }
    else{
      setIsReady(true);
    }


    return () => {
      AuthTokenClient.resetComponent(null, null);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    AuthTokenClient.setLoginData(session.data);

    if(session.data?.user){
      myInfo.refreshMyinfo();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.data]);

  return (isReady && children);
}
