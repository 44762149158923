'use client';

import { Provider } from 'jotai';
import { siteConfig } from '@/config/site.config';
import hideRechartsConsoleError from '@/utils/recharts-console-error';
import { ThemeProvider as NextThemeProvider } from 'next-themes';
import { applyDefaultColorPreset } from '../composables/settings/use-theme-color';

// const isDebugMode = process.env.NEXT_PUBLIC_DEV_MODE == 'true';

hideRechartsConsoleError();

export function ThemeProvider({ children }: React.PropsWithChildren) {
  applyDefaultColorPreset();


  // useEffect(() => {
  //   plugin_dayjs();
  //   plugin_zod();

  //   if(isDebugMode){
  //     //@ts-ignore
  //     window._dayjs = dayjs;
  //   }


  //   AuthTokenClient.resetComponent(session.update, signOut);
  //   AuthTokenClient.setLoginData(session.data);


  //   return () => {
  //     AuthTokenClient.resetComponent(null, null);
  //   };

  // }, []);
  
  // useEffect(() => {
  //   AuthTokenClient.setLoginData(session.data);
  // }, [session.data]);

  return (
    <NextThemeProvider enableSystem={false} defaultTheme={String(siteConfig.mode)}>
      {children}
    </NextThemeProvider>
  );
}

export function JotaiProvider({ children }: React.PropsWithChildren) {
  return <Provider>{children}</Provider>;
}
