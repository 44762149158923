/*
bun add add zod-metadata
*/
import { register } from 'zod-metadata';
import zod, { z, ZodErrorMap, ZodIssueCode } from 'zod';


export function plugin_zod() {
    const customErrorMap: ZodErrorMap = (issue, ctx) => {
        switch (issue.code) {
            case ZodIssueCode.invalid_type:
                return { message: issue.received === "undefined" ? "필수 값이 누락되었습니다." : "잘못된 데이터 유형입니다."};
            case ZodIssueCode.too_small:
                return { message: (issue.minimum == 1 && issue.type == 'string') ? "필수 값이 누락되었습니다" : issue.type == 'string' ? "값이 너무 짧습니다." :"값이 너무 작습니다." };
            case ZodIssueCode.too_big:
                return { message: "값이 너무 큽니다." };
            case ZodIssueCode.invalid_string:
                return { message: "잘못된 문자열 형식입니다." };
            default:
                return { message: ctx.defaultError };
        }
    };

    z.setErrorMap(customErrorMap);
}

export function plugin_zod_meta() {
    register(zod);
}
