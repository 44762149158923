import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'


export function plugin_dayjs(){
  dayjs.extend(utc);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(relativeTime);
}