'use client';

import { metaObject } from '@/config/site.config';
import { PiCheckCircle, PiScanSmiley } from 'react-icons/pi';
import { ActionIcon, Button, Select, SelectOption, Switch } from 'rizzui';
import { ShowAlert, ShowYesNoBox } from '../alerts';
import { useNotiDialog } from '../modal-views/noti-dialog';
import { useEffect, useState } from 'react';
import { debug_app_openQR, debug_initappChannel } from '../barcode/app_protocol';
import { useSession } from 'next-auth/react';
import dayjs from 'dayjs';

const isDebugMode = process.env.NEXT_PUBLIC_DEV_MODE == 'true';

export const metadata = {
  ...metaObject(),
};

export default function DebugPanel() {
  const { showNotiDialog } = useNotiDialog();
  const [enableTestButton, SetEnableTestButton] = useState(false);
  const [barcodeDebug, setBarcodeDebug] = useState(null);
  const session = useSession();

  useEffect(()=>{
    if(isDebugMode){
      // setBarcodeDebug({ value: '0108806507011325', label: "제조일포함" }),
      // onSetAppRecevier('0108806507011325');
      setBarcodeDebug({ value: '01088065070113251721101210CTMAV55721B0000000067131', label: "제조일포함" }),
      onSetAppRecevier('01088065070113251721101210CTMAV55721B0000000067131');
    }
  }, []);


  function onSetAppRecevier(behavior: string) {
    debug_initappChannel(behavior);
  }

  return (
    <div className='fixed left-0 sm:top-0 bottom-0 sm:bottom-auto z-[100] bg-white border'>
      <div className='font-bold'>Debug Panel</div>
      <div className='flex gap-4 items-center px-4'>
        <div className='-me-4'>바코드</div>
        <Select
          as='div'
          size='sm'
          variant='outline'
          options={[
            { value: 'none', label: "None App" },
            { value: 'cancel', label: "Barcode Cancel" },
            { value: '12345678', label: "Barcode OK" },
            { value: '8800527001533', label: "레보리드정" },
            { value: '8800527001823', label: "티오셋 30정" },
            { value: '8800527001816', label: "티오셋 300정" },
            { value: '00388005460301759580', label: "묶음번호(SSCC)" },
            { value: '01088065070113251721101210CTMAV55721B0000000067131', label: "제조일포함" },
          ]}
          className='w-13'
          value={barcodeDebug}
          onChange={(v: SelectOption) => {
            setBarcodeDebug(v);
            onSetAppRecevier(v.value as string);
          }}
          suffixClassName='[&>svg]:size-3'
          selectClassName='font-semibold text-xs ring-0  h-7 w-36'
          optionClassName='font-medium text-xs px-2 justify-center'
        />
        <ActionIcon size='sm' variant='outline' className='-ms-3' onClick={debug_app_openQR}>
          <PiScanSmiley/>
        </ActionIcon>

        <Switch label='Test Buttons' onChange={(e) => SetEnableTestButton(e.target.checked)}></Switch>
      </div>

      {enableTestButton && (
        <div>
          <div>
            token exp: { dayjs(session.data?.user.tkexp*1000).format()} ({dayjs(session.data.user.tkexp*1000).fromNow()})
          </div>
          <div>
            session exp: { dayjs(session.data?.user.sexp*1000).format()} ({dayjs(session.data.user.sexp*1000).fromNow()})
          </div>
          <Button
            onClick={async () => {
              console.warn(await session.update());
            }}
          >
            토큰 업데이트
          </Button>
          <Button
            onClick={() => {
              ShowAlert('Alert Test');
            }}
          >
            Alert
          </Button>
          <Button
            onClick={() => {
              ShowYesNoBox('Alert Test');
            }}
          >
            Yes No
          </Button>

          <Button
            onClick={() => {
              showNotiDialog({
                icon: <PiCheckCircle className='text-green-500' />,
                title: '타이틀',
                description: `제품 ㅇㅇㅇㅇㅇ 등록이 완료되었습니다.`,
                buttons: [{ text: '확인' }],
              });
            }}
          >
            noti full
          </Button>
          <Button
            onClick={() => {
              showNotiDialog({
                icon: <PiCheckCircle className='text-green-500' />,
                title: (<div>
                    입고가 완료 되었습니다.
                  </div>
                ) ,
                description:(
                  <div>
                    <div className='whitespace-pre py-8 leading-loose text-center'>
                    총 2개 품목의 제품이 60개 입고 되었습니다.
                    </div>
                  <div className='text-center'>
                    <Button variant='flat' color='secondary' size='md' >상세 내역 보기</Button>
                  </div>
                  </div>
                ),
                buttons: [{ text: '확인' }],
              });
            }}
          >
            noti 입고
          </Button>

          <Button
            onClick={() => {
              showNotiDialog({
                icon: <PiCheckCircle className='text-green-500' />,
                title: `제품 ㅇㅇㅇㅇㅇ 등록이 완료되었습니다.`,
                buttons: [{ text: '확인' }],
              });
            }}
          >
            icon + title
          </Button>
        </div>
      )}
    </div>
  );
}
