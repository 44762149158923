'use client';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { ActionIcon, Modal } from 'rizzui';
import { g_barcode_use, useBarcodeReader } from './use-barcode';
import { TAppBarcodeResponse } from './app_protocol';
import cn from '../../utils/tailwind/utils';
import { useEffect, useState } from 'react';
import { ShowAlert } from '../alerts';
import { PiXBold } from 'react-icons/pi';

//시간날때 @zxing/library 찾아서 소스로 처리하면 좀 더 나울수도...

export function BarcodeScanner({ onReadCode }: { onReadCode: (param: TAppBarcodeResponse) => void }) {
  const [scanData, setScanData] = useState<string>('scanning...');

  const cancelScan = () => {
    onReadCode({ status: 'cancel', code: null });
  };

  // const canvasRef = useRef<HTMLCanvasElement>(null);

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext('2d');
  //   context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
  // }, []);

  // const drawBoundingBox = (points: ResultPoint[] | undefined) => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext('2d');

  //   if (!points || points.length < 2) return;

  //   // 캔버스 초기화
  //   context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);

  //   // 탐지된 영역 표시
  //   context.beginPath();
  //   context.moveTo(points[0].x, points[0].y);
  //   points.forEach((point) => context.lineTo(point.x, point.y));
  //   context.closePath();

  //   context.lineWidth = 4;
  //   context.strokeStyle = 'red';
  //   context.stroke();
  // };

  return (
    <div className={cn('p-0 rounded-xl')}>
      <h4 className='flex justify-between bg-black ps-3 py-1 rounded-t-xl'>
        <span className='text-white text-lg font-semibold'>{scanData}</span>
        <ActionIcon size='sm' rounded='full' variant='text' onClick={cancelScan}>
          <PiXBold className='h-4 w-4 text-white' />
        </ActionIcon>
      </h4>
      <div className='relative'>
        <BarcodeScannerComponent
          // width={100}
          // height={100}
          delay={200}
          onUpdate={(err, result) => {
            if (result) {
              // const readText = result.getText().replace(/[^a-zA-Z0-9:]/g, '');
              // const readText = result.getText().replace(/[^a-zA-Z0-9:/?]/g, "");
              const readText = result.getText();

              setScanData(result.toString());
              // drawBoundingBox(result.getResultPoints());
              console.log(readText);

              if (/^\d/.test(readText)) {
                return onReadCode({ status: 'ok', code: readText });
              }
              // if(readText.replace(/[^0-9]+/g, '') == readText){
              //   onReadCode({status:'ok', code: readText});
              // }
            }
          }}
          onError={async (error: any) => {
            if (error.name == 'NotAllowedError') {
            } else if (error.name == 'NotFoundError') {
            } else {
            }
            cancelScan();
            await ShowAlert(String(error), { title: '바코드 스캐너 오류' });
          }}
        />
        {/* <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0 }} /> */}
      </div>
    </div>
  );
}

export function BarcodeScannerContainer() {
  const { isOpenWebScanner, closeBarcodeReader, loadWebSetting } = useBarcodeReader();

  useEffect(() => {
    loadWebSetting();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fn_barcode = (param: TAppBarcodeResponse) => {
    console.log(`📟 onReadCode: `, param);

    if (g_barcode_use.onReadCode) {
      g_barcode_use.onReadCode(param);
    }
    closeBarcodeReader();
  };

  return (
    <Modal
      isOpen={isOpenWebScanner}
      onClose={() => {
        return null;
      }}
      customSize='100%'
      overlayClassName='dark:bg-opacity-40 dark:backdrop-blur-lg'
      containerClassName={cn('dark:bg-gray-100 w-full sm:w-fit')}
      className='z-[100000] [&_.pointer-events-none]:overflow-visible'
    >
      <BarcodeScanner onReadCode={fn_barcode} />
    </Modal>
  );
}
