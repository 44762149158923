'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { Modal } from 'rizzui';
import { useModal } from './use-modal';
import cn from '@/utils/tailwind/utils';

export default function GlobalModal() {
  const { isOpen, view, closeModal, customSize, size, containerClassName, isModal } = useModal();
  const pathname = usePathname();
  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={()=>{return isModal ? null : closeModal()}}
      customSize={customSize}
      size={size}
      overlayClassName="dark:bg-opacity-40 dark:backdrop-blur-lg"
      containerClassName={cn("dark:bg-gray-100 w-full sm:w-fit", containerClassName)}
      className="z-[9999] [&_.pointer-events-none]:overflow-visible"
    >
      {view}
    </Modal>
  );
}
