import { Metadata } from 'next';
import logoImg from '@/public/logo1.png'
import logoIconImg from '@/public/logo_icon.png';
import { OpenGraph } from 'next/dist/lib/metadata/types/opengraph-types';
import { routes } from './routes';

enum MODE {
  DARK = 'dark',
  LIGHT = 'light',
}

export const siteConfig = {
  title: 'mchain',
  description: `mchain 물류 관리 프로그램`,
  logo: logoImg,
  icon: logoIconImg,
  mode: MODE.LIGHT,
  layout: 'hydrogen',
};

export const metaObject = (
  title?: string,
  openGraph?: OpenGraph,
  description: string = siteConfig.description
): Metadata => {
  return {
    title: title ? `${title} - m2Cloud` : siteConfig.title,
    description,
    openGraph: openGraph ?? {
      title: title ? `${title} - m2Cloud` : title,
      description,
      url: 'https://www.m2cloud.kr/',
      siteName: 'mchain.kr', // https://developers.google.com/search/docs/appearance/site-names
      images: {
        url: 'https://www.m2cloud.kr/sian/img/logo1.png',
        width: 300,
        height: 100,
      },
      locale: 'en_US',
      type: 'website',
    },
  };
};

export function getCustomerSignIn(data:{orgId:number, urlcode:string}){
  return `${window.location.origin}${routes.auth.signUp}/${data.orgId}_${data.urlcode}`;
}