'use client';

import { atom, useAtomValue, useSetAtom } from 'jotai';
import { api_call } from '../../composables/api/api_call';


type MyInfo = {
  id: number
  loginId: string;
  name: string;
  email?: string
  roles?: string;
  orgId: number
  orgName: string;
  locationId?: string;
  locationName?: string;
};

const dataAtom = atom<MyInfo>();

export function useMyInfo() {
  const state = useAtomValue(dataAtom);
  const setState = useSetAtom(dataAtom);

  const refreshMyinfo = async ()=>{
    const response = await api_call<MyInfo>('/user/myinfo', {
      param: {},
    });
    if(response.result){
      setState({
        ...response.result
      });
      return response.result;
    }
  }
  
  return {
    myInfo: state,
    refreshMyinfo,
  };
}
