'use client';

import { useEffect, useState } from 'react';
import { setGlobalLoadingHook, useGlobalLoading } from './use-loading';
import cn from '@/utils/tailwind/utils';
import LoadingCylonIcon from '../icons/loading-cylon';


// const isDebugMode = process.env.NEXT_PUBLIC_DEV_MODE == 'true';


export default function GlobalLoading() {
  const { isLoading, text, delay, openLoading, closeLoading } = useGlobalLoading();


  const [classVisible, setClassVisible] = useState<string | null>(null); // 애니메이션 처리를 위해 로컬 상태 사용

  useEffect(() => {
    setGlobalLoadingHook({ openLoading, closeLoading });
    // openLoading({text:''})


    return () => {
      setGlobalLoadingHook(null);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (isLoading) {
      setClassVisible('opacity-0');
      const timer = setTimeout(() => setClassVisible('fade-in'), delay ?? 400);
      return () => clearTimeout(timer);
    } else {

      if(classVisible == 'opacity-0'){
        setClassVisible(null);
        return;
      }

      setClassVisible('fade-out');
      const timer = setTimeout(() => setClassVisible(null), 500);
      return () => clearTimeout(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (!classVisible) return null;

  return (
    <div
      className={cn(
        'fixed flex flex-col items-center justify-center left-0 right-0 top-0 bottom-0 bg-black/60 text-gray-200 z-[10000] select-none',
        classVisible
      )}
    >
      <LoadingCylonIcon className='w-64 h-64 text-gray-100' />
      <p
      // onClick={closeLoading}
      >
        {text ?? ''}
      </p>
    </div>
  );
}
